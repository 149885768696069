@import "variables";
@import "colors";

@mixin phone {
  @media (min-width: 0px) and (max-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $sm + 1) and (max-width: $md) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $md) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin internet-explorer {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}