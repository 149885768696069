@import "mediaHelpers";

:-moz-ui-invalid {
  box-shadow: none;
}

.ant-layout,
.ant-drawer,
.ant-modal {
  input[type="color"] {
    border: none;
    width: 50px;
    height: 50px;
    background-color: transparent;
  }

  input,
  select,
  textarea,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 30px;
    font-weight: normal;
    font-size: 0.9rem;
    border: none;
    border-bottom: 1px solid $primary-blue;
    width: 100%;

    &:focus {
      outline: none;
      border-bottom-color: $primary-blue;
    }

    &.error {
      border-color: red;
    }
  }

  input,
  select,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    max-height: 30px;
    border-radius: 0;
  }

  .tag-check-container {
    text-align: center;

    .tag-check {
      text-align: center;
      width: 30px;
      height: 30px;
      padding: 5px 5px;
      margin: 4px;
      border-radius: 50%;
      line-height: 20px;

      &:last-child {
        width: 70px;
        border-radius: 24px;
      }

      &.active {
        background-color: $primary-blue;
        border: 1px solid $primary-blue;
        color: #ffffff;
      }
    }
  }

  textarea {
    padding: 5px 5px 0 5px;
    border: 1px solid $primary-blue;
    border-radius: 5px;
  }

  .ant-radio-group.ant-radio-group-outline {
    width: 100%;
    text-align: center;

    .ant-radio-button-wrapper {
      text-align: center;

      &:not(:first-child)::before {
        display: none;
      }

      &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }

      &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      &:not(:first-child) {
        margin-left: -15px;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: $primary-blue;
        border-radius: 50px;
        color: #fff;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .form-item.error {
    select,
    input {
      border-bottom-color: red;
    }
  }

  .ant-input-affix-wrapper {
    input {
      border: none;
    }

    &:hover {
      z-index: auto;
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }

  select,
  input,
  .ant-input-affix-wrapper{
    padding: 0 2px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $primary-blue;

    &:focus {
      box-shadow: none;
    }

    &.ant-select-single .ant-select-selector {
      border: none;
      padding: 0;
    }
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #fff;
    padding: 0;
    line-height: normal;

    option {
      padding: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .file-uploader {
    display: flex;
    justify-content: center;

    .ant-upload {
      float: unset;

      img {
        width: 102px;
        height: 102px;
        object-fit: contain;
      }
    }
  }
}

.color-picker {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 39px;
  }
}
