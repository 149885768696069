$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$border-radius: 24px;
$card-width: 100%;

$nav-bar-height: 52px;

$general-border: 1px solid #f0f0f0;
$gradient: linear-gradient(130deg, #002396, #24b7ff);
$box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);