@import "mediaHelpers";
@import "antd";
@import "input";
@import "variables";

body,
html,
.ant-layout,
#root,
.app-container {
  min-height: calc(100vh - $nav-bar-height) !important;
  font-family: Dosis, Roboto, sans-serif;
}

main {
  min-height: calc(100vh - $nav-bar-height) !important;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.app-container > .ant-layout {
  max-width: 1000px;
  padding: 20px;
  width: 100vw;
  margin: auto;

  @include mobile {
    padding: 0;
  }
}

.clickable {
  cursor: pointer;
}

.crossed-out {
  text-decoration: line-through;
}

.muted {
  color: #00000073;
  font-size: 14px;
}

.d-flex {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}


$spaceAmounts: (-20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 70, 75, 80, 100);
$sides: (top, bottom, left, right, center);


@each $space in $spaceAmounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $side in $sides {
  .text-#{$side} {
    text-align: #{$side} !important;
  }

  .float-#{$side} {
    float: #{$side}
  }
}

@each $space in $spaceAmounts {
  .w-#{$space} {
    width: #{$space}px !important;
  }

  .mw-#{$space} {
    min-width: #{$space}px !important;
  }
}

.w-full {
  width: 100% !important;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: white;
  }
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.text-muted {
  color: #00000073;
}

.text-primary {
  color: #277ebb;
}

.box-shadow {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
}

.client-item {
  height: 40px;
  text-align: center;
  background: #277ebb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
}