@import "colors";
@import "mediaHelpers";
@import "variables";

.ant-layout {
  height: 100%;

  .ant-layout-content {
    padding: 20px;

    @include phone {
      padding: 10px;
    }
  }

  .ant-layout-header {
    background: $primary-blue;
    color: white;
    padding: 0 20px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $nav-bar-height;

    @include desktop {
      padding: 0 20px;
    }
  }

  .ant-layout-sider .ant-layout-sider-zero-width-trigger {
    background: transparent;
    position: absolute;
    top: -45px;
    left: 0;
    color: #FFFFFF;
  }
}

.ant-pagination-options {
  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        display: flex;
        align-content: center;
        align-items: center;
      }
    }
  }
}

.ant-layout-sider {
  .ant-menu {
    height: 100%;

    li:last-child.logout {
      width: 100%;
      margin: 0;
      background: $primary-blue;
      color: white;
      position: absolute;
      bottom: 0;
    }
  }
}

.ant-descriptions {
  .ant-descriptions-item {
    .ant-descriptions-item-label {
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
}

.ant-card {
  .ant-card-body {
    @include mobile {
      padding: 10px;
    }
  }
}

.ant-modal-root {
  @include mobile {
    .ant-modal {
      width: 100vw;
      max-width: 100vw;
      margin: 0;
      top: 0;
      bottom: 0;
      padding: 0;

      .ant-modal-body {
        min-height: calc(100vh - 55px - 53px);
        overflow: scroll;
      }
    }
  }
}

.ant-table-wrapper .ant-spin-nested-loading {
  min-height: 300px;
}


.ant-select {
  .ant-select-selector {
    box-shadow: none !important;
  }
}

.antd-upload-button, iframe {

  &.media-format {
    margin-bottom: 30px;
    margin-right: 20px;
    border-collapse: collapse;

    img {
      object-fit: cover;
      border: 1px solid #ece6e7;
    }
  }

  &.format-4-3, &.format-4-3 img {
    width: 150px !important;
    height: 200px !important;
  }

  &.format-16-9, &.format-16-9 img {
    width: 250px !important;
    height: 140px !important;
  }

  &.format-1-1, &.format-1-1 img {
    width: 200px !important;
    height: 200px !important;
  }

  &.format-18-6, &.format-18-6 img {
    width: 250px !important;
    height: 125px !important;
  }

  &.preview {
    background: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 500px;
  overflow: scroll;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0 !important;
}